import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Combobox, Transition } from '@headlessui/react'

export default function SearchBox({ selectedData, datasource, comboboxChnage }) {
    const [query, setQuery] = useState('')
    const [open] = useState(false)

    const filteredData =
        query === ''
            ? datasource
            : datasource.filter((data) =>
                data.name
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )

    const searchInput = useRef()
    const buttonCtrlK = useRef()

    function handleCtrlK(event) {
        // console.log(event)
        if ((event.ctrlKey || event.metaKey) && event.key === "k") {
            event.preventDefault();
            if (searchInput.current)
                searchInput.current.focus()
            if (buttonCtrlK.current)
                buttonCtrlK.current.click()
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", handleCtrlK);
        return () => {
            document.removeEventListener("keydown", handleCtrlK);
        }
    }, [])

    const [isMacOS, setIsMacOS] = useState(false)
    useEffect(() => {
        if (typeof window !== `undefined`) {
            // your code here
            const userAgent = window.navigator.userAgent;
            setIsMacOS(userAgent.includes('Mac OS'))
        }
    }, [])

    return (
        <div className="w-full">
            <Combobox value={selectedData} onChange={comboboxChnage} open={open}>
                <div className="relative mt-1">
                    <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus-within:shadow-lg
                    focus-within:outline-none focus-within:ring-2 focus-within:ring-white focus-within:ring-opacity-75 h-[44px] focus-within:h-[60px]
                    focus-within:ring-offset-2 focus-within:ring-offset-teal-300 transition-all duration-300">
                        <Combobox.Input
                            ref={searchInput}
                            className="w-full border-none outline-none py-2 pl-3 pr-10 text-base leading-5 text-gray-900 focus:ring-0 h-[44px] focus:h-[60px] transition-all duration-300"
                            displayValue={(item) => item.name}
                            onChange={(event) => setQuery(event.target.value)}
                            placeholder="Search your word :D"
                        />
                        <Combobox.Button
                            ref={buttonCtrlK}
                            className="absolute inset-y-0 right-0 md:flex items-center pr-2 hidden">
                            <div className="flex gap-1 border rounded-md p-1">
                                <div>{isMacOS ? 'Cmd' : 'Ctrl'}</div>
                                <div>+</div>
                                <div>K</div>
                            </div>
                        </Combobox.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery('')}
                    >
                        <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filteredData.length === 0 && query !== '' ? (
                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                    Nothing found.
                                </div>
                            ) : (
                                filteredData.map((item) => (
                                    <Combobox.Option
                                        key={item.id}
                                        className={({ active }) =>
                                            `relative cursor-pointer select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                                            }`
                                        }
                                        value={item}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <span
                                                    className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                        }`}
                                                >
                                                    {item.name}
                                                </span>
                                                {selected ? (
                                                    <span
                                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                            }`}
                                                    >
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Combobox.Option>
                                ))
                            )}
                        </Combobox.Options>
                    </Transition>
                </div>
            </Combobox>
        </div>
    )
}
