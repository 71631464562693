import React, { useState } from "react";
import { navigate } from "gatsby";
import Layout from "@/components/layout"
import ChipPartOfSpeech from "@/components/chipPartOfSpeech"
import SearchBox from "@/components/searchBox"
import usePageBottom from "@/hooks/usePageBottom";
import { SEO } from "@/components/seo"

export const Head = () => (
  <SEO />
)

export default function IndexTemplate({ pageContext }) {
  const { pages } = pageContext;
  // const pagesFilter = pages.filter(page => !(page.slug === 'for_test' && process.env.NODE_ENV === 'production'))

  const [selected, setSelected] = useState()
  const [datasource] = useState(pages.map((m, i) => {
    return {
      id: i + 1,
      name: `${m.name}${m.titleThai ? ' (' + m.titleThai + ')' : ''}`,
      slug: m.slug
    }
  }))

  const { betweenBottom } = usePageBottom()


  function comboboxChnage(e) {
    // console.log(e)
    setSelected(e)
    navigate(`/${e.slug}`)
  }
  return (
    <Layout beforeContent={
      <div className={`hero-warpper bg-gradient-to-r from-primary to-blue-500 p-4 w-full min-h-[400px] 
      flex items-center justify-center fixed top-[80px] ${betweenBottom && 'min-h-[50px] translate-y-[0px]'} transition-all duration-300 z-50`}>
        <div className="md:w-[600px] w-full">
          <SearchBox selected-data={selected} comboboxChnage={comboboxChnage} datasource={datasource}></SearchBox>
        </div>
      </div>
    }>
      <div className={`${betweenBottom ? 'pt-[16rem]' : 'pt-[420px]'} mb-[100px]`}>
        <h3 className="text-center font-light">Recently Added</h3>
        <hr className="mt-4" />
        <ol className={`flex items-center justify-center flex-col gap-4 mt-4`}>
          {pages.map((page, i) => (
            <li key={`link_${i + 1}`} onClick={() =>
              navigate(`/${page.name}`)} aria-hidden="true" className="md:w-[600px] w-full cursor-pointer bg-white p-4 shadow-md rounded-lg hover:translate-y-1 hover:translate-x-[-0.25rem] hover:bg-gray-100 transition-all duration-300">
              <div className="flex md:gap-2 gap-1 items-center flex-wrap no-underline hover:underline decoration-yellow-500 transition-all duration-300">
                <div className="text-xl">{page.name.replaceAll("_", " ")}</div>
                <div className="text-md self-end font-light">{page.titleThai.replaceAll("_", " ")}</div>
              </div>
              <div className="flex gap-1 mt-1">
                {
                  page.partOfSpeech && page.partOfSpeech.map((pos, j) =>
                    <ChipPartOfSpeech key={j} content={pos}></ChipPartOfSpeech>
                  )
                }
              </div>
            </li>
          ))}
        </ol>
      </div>
    </Layout>
  );
}
